@import "../../styles/3-helpers/mixin";
@import "../../styles/0-base/variables";

hr {
  height: 0.125rem !important;
  margin: 0;
  border-width: 0;
  color: #eaedef;
  background-color: #eaedef;
}

.flex-center {
  @include flex(center, center);
}

.avatar {
  object-fit: contain;
  text-transform: uppercase;
  @include flex(center, center);
}

.bt {
  border-top: 0.0625rem solid #eaedef !important;
}

.bb {
  border-bottom: 0.0625rem solid #eaedef !important;
}

.h-40 {
  height: 2.5rem;
}

.h-36 {
  &.form-control {
    height: 2.25rem !important;
  }

  &.form-ngselect .ng-select-container {
    height: 2.25rem !important;
  }
}

.border_rad_4 {
  border-radius: 0.25rem;
}

.outline_none {
  outline: none;
}

.border_none {
  border: none;
}

.h-fullscreen {
  height: 100vh !important;
}

.cropContainer {
  border-radius: 1.5rem !important;
  box-shadow: 0rem 0.5rem 1rem 0rem #121b211a !important;
}

.track-i {
  background: #0066dd !important;
  height: 0.5rem !important;
  border-radius: 0.25rem 0rem 0rem 0.25rem !important;
}

.y-bg-j {
  background: #dae2e8 !important;
  height: 0.5rem !important;
  box-shadow: inset 0rem 0.125rem 0.125rem -0.0625rem rgba(74, 74, 104, 0.1) !important;
  border-radius: 0.5rem !important;
}

.thumb-m {
  background: #ffffff !important;
  border: 0.0625rem solid #dae2e8 !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
  top: -0.6875rem !important;
  box-shadow: none !important;
  border-radius: 2.75rem !important;

  &:hover {
    box-shadow: 0rem 0rem 0rem 0.375rem rgba(105, 112, 117, 0.1) !important;
  }

  &:hover:before {
    box-shadow: 0rem 0rem 0rem 0.375rem rgba(105, 112, 117, 0.1) !important;
  }

  &:focus:before {
    box-shadow: 0rem 0rem 0rem 0.375rem rgba(105, 112, 117, 0.1) !important;
  }
}

.crop-container {
  box-shadow: 0rem 0.5rem 1rem 0rem #121b211a;
  // border-radius: 1.5rem;
  overflow: hidden;
  min-height: 15rem;
}

.crop-container h4 {
  padding: 1.5rem;
  margin-bottom: 0;
}

// .horizontal-p {
//   width: 90%;
//   top: 2.1875rem !important;
// }
.y-bg-j {
  opacity: 1 !important;
}

.text-trim {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.w-15ch {
  max-width: 15ch !important;
}
.w-17ch {
  max-width: 17ch !important;
}

.w-18ch {
  max-width: 18ch !important;
}

.statistic {
  text-transform: uppercase;
  display: inline-block;
  font-size: 0.6563rem;
  font-weight: 500;
  margin-right: auto;
  display: flex;
  align-items: center;

  span {
    display: flex;
    align-items: center;
  }

  span::before {
    content: "";
    width: 0.25rem;
    height: 1rem;
    border-radius: 0.25rem;
    margin-right: 0.25rem;
    background: #eaedef;
    position: relative;
    display: inline-block;
  }

  &.bg-primary,
  &.bg-success,
  &.bg-accent1,
  &.bg-warning,
  &.bg-info,
  &.bg-danger,
  &.bg-47,
  &.bg-48,
  &.bg-accent1-hover {
    background-color: transparent !important;
  }

  &.bg-primary {
    --color: #0078ce;

    span {
      color: var(--color);

      &:before {
        background: var(--color);
      }
    }
  }

  &.bg-success {
    --color: #28a745;

    span {
      color: var(--color);

      &:before {
        background: var(--color);
      }
    }
  }

  &.bg-accent1 {
    --color: #31aab7;

    span {
      color: var(--color);

      &:before {
        background: var(--color);
      }
    }
  }

  &.bg-warning {
    --color: #e29f24;

    span {
      color: var(--color);

      &:before {
        background: var(--color);
      }
    }
  }

  &.bg-info {
    --color: #6562cf;

    span {
      color: var(--color);

      &:before {
        background: var(--color);
      }
    }
  }

  &.bg-danger {
    --color: #dc3545;

    span {
      color: var(--color);

      &:before {
        background: var(--color);
      }
    }
  }

  &.bg-accent1-hover {
    --color: #129aa6;

    span {
      color: var(--color);

      &:before {
        background: var(--color);
      }
    }
  }

  &.bg-47 {
    --color: #622776;

    span {
      color: var(--color);

      &:before {
        background: var(--color);
      }
    }
  }

  &.bg-48 {
    --color: #7d92a1;

    span {
      color: var(--color);

      &:before {
        background: var(--color);
      }
    }
  }
}

.user-card {
  --color: #8ad7e0;
  background: linear-gradient(var(--color) 2.75rem,
      #ffffff 2.75rem,
      #ffffff 100%);

  &.bg0 {
    --color: #8ad7e0;
  }

  &.bg1 {
    --color: #d7b09a;
  }

  &.bg2 {
    --color: #c0afdb;
  }

  &.bg3 {
    --color: #e0bd75;
  }

  &.uniquebg1 {
    --color: #8ad7e0 !important;
  }

  &.uniquebg2 {
    --color: #e0bd75 !important;
  }

  &.uniquebg3 {
    --color: #eac2ac !important;
  }

  &.uniquebg4 {
    --color: #c0afdb !important;
  }

  &.uniquebg5 {
    --color: #96b2e0 !important;
  }

  &.uniquebg6 {
    --color: #8be0b0 !important;
  }

  &.uniquebg7 {
    --color: #d2a1e0 !important;
  }

  &.uniquebg8 {
    --color: #bce080 !important;
  }
}

.list-type-none {
  list-style-type: none;
  padding-left: 0;
}

// *************************************
//*********Bootstrap Pagination*********
// *************************************
.pagination {
  margin-bottom: 0;
  --bs-pagination-border-radius: 0.125rem;

  .page-item+.page-item {
    padding-left: 0.5rem;
  }

  .page-link {
    border: 0.0625rem solid #e4e8ea;
    border-radius: 0.125rem;
    background: #ffffff;
    color: #7d92a1;
    font-size: 0.875rem;
    width: 2rem;
    height: 2rem;
    aspect-ratio: 1;
    @include flex(center, center);

    &:active,
    &:focus {
      box-shadow: none;
    }
  }

  .page-item.active .page-link span {
    display: none;
  }

  .page-item.active .page-link {
    background-color: #fff;
    border-color: #31aab7;
    color: #31aab7;
    z-index: 0;
  }
}

// *************************************
//*********         END         ********
// *************************************
.title2 {
  font-weight: 500;
  text-transform: uppercase;
}

.graph-increase,
.graph-decrease {
  position: relative;
  padding-left: 1.0625rem;

  &::before {
    font-family: bootstrap-icons !important;
    position: absolute;
    // border-style: solid;
    // border-width: .375rem;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.graph-increase::before {
  color: #28a745;
  content: "\F235";
}

.graph-decrease::before {
  color: #dc3545;
  content: "\F229";
}

//**************************
//*******Chart Plugin*******
//**************************
.chart-legend .legend-labels {
  background-color: transparent !important;
  text-align: right !important;
  float: right !important;
}

.legend-label-text {
  color: #324552;
}

.active .legend-label-text {
  color: $medium-contrast-light !important;
}

//**************************
//**************************
.list-steps {
  display: flex;
  align-items: flex-start;

  .step {
    white-space: nowrap;
    background: #0078ce;
    color: #fff;
    border-radius: 1.5rem;
    padding: 0.375rem 1rem;
    margin-right: 1rem;
  }

  .details {
    padding-top: 0.375rem;
  }
}

.tooltip-icon:hover::before {
  content: "\F430" !important;
}

.dot {
  position: relative;
  padding-left: 0.75rem;

  &:before {
    position: absolute;
    content: "";
    @include sq(0.5rem);
    border-radius: 50%;
    background-color: #e4e8ea;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.text-success .dot:before {
  background-color: $text-success;
}

.text-accent2 .dot:before {
  background-color: $accent-02-active;
}

//************************************
//*********     PDF VIEWER    ********
//************************************
.pdf-container {
  height: calc(80vh - 4.375rem) !important;
  display: block;
  // width: 80%;
}

.pdfViewer .canvasWrapper {
  width: 100% !important;
}

.pdfViewer.removePageBorders .page {
  width: 100% !important;
}

.pdfViewer .page canvas {
  width: 100% !important;
}

.modal-pdfviewer {
  .modal-dialog:not(.modal-lg) {
    max-width: 43.1875rem !important;
  }

  .modal-content {
    border-radius: 0.5rem !important;
  }

  .modal-body {
    background: #f8fafb !important;
    border-radius: 0 0 0.5rem 0.5rem !important;
  }
}

::ng-deep .ng2-pdf-viewer-container {
  inset: 0;
  width: 80% !important;
  height: calc(100% - 1.375rem) !important;
  margin: 1.375rem auto;
}

pdf-viewer {
  height: calc(100% - 1.375rem) !important;
}

// *************************************
//*********         END         ********
// *************************************
.loader.progress {
  margin-bottom: 0rem !important;
}

// country code
.flag_div {
  // width: 6.25rem;
  // border: 0.0625rem solid #dae2e8;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  background-color: #f8fafb;
  display: flex;
  align-items: center;
  left: 1px;
  top: 1px;
  height: calc(100% - 2px);
  width: 4.0625rem;
}

//
.sticky-footer {
  position: sticky;
  bottom: 0;
  background-color: #fff;
  z-index: 2;
}

.search-dropdowns .drop-down-item .imgitem {
  height: 1rem !important;
  width: 1rem !important;
  margin-right: 0.25rem;
}

.text-trim-2,
.text-trim-3,
.text-trim-5,
.text-trim-4 {
  display: -webkit-box;
  // max-width: 12.5rem;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-trim-2 {
  -webkit-line-clamp: 2;
}

.text-trim-3 {
  -webkit-line-clamp: 3;
}

.text-trim-4 {
  -webkit-line-clamp: 4;
}
.text-trim-5 {
  -webkit-line-clamp: 5;
}

.mat-paginator {
  //Resets
  background: none;

  .mat-paginator-range-label,
  .mat-paginator-page-size,
  .mat-button-wrapper {
    display: none;
  }

  .mat-paginator-container {
    padding: 0 !important;
    min-height: auto;
  }

  .mat-custom-page:active {
    box-shadow: none !important;
  }

  .mat-paginator-range-actions {
    gap: 0.5rem;
  }

  .mat-focus-indicator,
  .mat-custom-page {
    width: 2rem;
    height: 2rem;
    border: 0.0625rem solid #e4e8ea;
    border-radius: 0.125rem;
    background-color: #ffffff !important;
    padding: 0;
    font-size: 0.875rem;
    @extend .flex-center;
    box-shadow: none;
    color: #7d92a1 !important;
    margin: 0rem !important;
    font-weight: 400;
  }

  .mat-focus-indicator:disabled {
    cursor: default;
    opacity: 0.7;
  }

  .mat-focus-indicator:disabled::before {
    color: #7d92a1 !important;
  }

  .mat-focus-indicator:hover:enabled,
  .mat-custom-page:hover:not([disabled="disabled"]) {
    border: 0.0625rem solid #31aab7;
    color: #31aab7 !important;
  }

  .mat-custom-page[disabled="disabled"] {
    border: 0.0625rem solid #31aab7;
    color: #31aab7 !important;
  }

  .mat-focus-indicator::before {
    font-family: bootstrap-icons !important;
    inset: 0;
    font-size: 0.75rem;
    color: #31aab7;
  }

  .mat-paginator-navigation-first::before {
    content: "\F27F";
  }

  .mat-paginator-navigation-previous::before {
    content: "\f284";
  }

  .mat-paginator-navigation-next::before {
    content: "\F285";
  }

  .mat-paginator-navigation-last::before {
    content: "\F280";
  }
}

.empty-container {
  @extend .flex-center;
  flex-direction: column;
}

.chart-legend .legend-labels {
  margin-top: 0.5rem !important;
}

.w-30ch {
  max-width: 30ch !important;
}

.w-50ch {
  max-width: 50ch !important;
}

// .text-break{word-break: break-word;}
.pointer-event {
  pointer-events: none;
}

.box-1 {
  background-color: rgba(0, 120, 206, 0.1);
  border-radius: 0.25rem;
  padding: 0.75rem 1.5rem;
  display: flex;
  width: 100%;
}

.title {
  color: $high-contrast-light;
  font-weight: 600;
  font-size: 0.75rem;
  text-transform: uppercase;
  position: relative;
  display: block;

  &::after {
    content: "";
    width: 2.9375rem;
    position: absolute;
    height: 0.125rem;
    bottom: -0.5rem;
    background-color: black;
    left: 0;
  }
}

.y-overlay-a,
ly-overlay-container {
  z-index: $zIndex-modal !important;
}

.custom-checklist {
  list-style-type: none;
  padding: 0;
  margin: 0;

  li+li {
    margin-top: 0.625rem;
  }

  li {
    // background-color: #EFF4F6;
    border-radius: 0.25rem;
    padding: 0.75rem 1.88rem 0.75rem 3.188rem;
    font-size: 0.875rem;
    position: relative;

    // padding-left: 3.188rem;
    &::before {
      position: absolute;
      left: 0.75rem;
      top: 50%;
      transform: translateY(-50%);
      // content: "\F28A";
      // font-family: bootstrap-icons !important;
      // font-size: 1.688rem;
      // line-height: 1.688rem;
      // color: #DEE3E7 ;
      // border-radius: 50%;
      // background-color: #fff;
      content: "";
      width: 1.688rem;
      height: 1.688rem;
      border-radius: 50%;
      border: 0.0625rem solid #dee3e7;
      background-color: #fff;
    }

    &::after {
      content: "\F285";
      font-family: bootstrap-icons !important;
      position: absolute;
      right: 0.75rem;
      top: 50%;
      transform: translateY(-50%);
      color: #acbac3;
      font-size: 0.813rem;
    }
  }

  li:hover {
    background-color: #eff4f6;
    cursor: pointer;
  }

  li.success {
    color: #7d92a1;
    text-decoration: line-through;
    pointer-events: none;

    &::before {
      content: "\e91f"; //check
      font-family: "icomoon";
      background-color: #28a745;
      color: #fff;
      border: none;
      font-size: 0.813rem;
      @extend .flex-center;
    }
  }
}

.timer-display {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 0.25rem;
  min-width: 77px;
  font-weight: 600;
  font-size: 14px;
  padding-left: 8px;
  padding-right: 8px;
  // height: 100%;
}

.flex-basis-100 {
  flex-basis: 100%;
}

.pac-container {
  z-index: 2000 !important;
  font-family: "Work Sans", sans-serif;

  &::after {
    content: unset !important;
  }
}

// .cluster {
//   position: relative !important;
//   width: 55px !important;
//   height: 55px !important;
//   & > img {
//     width: 55px;
//   }
//   & > div {
//     left: 50%;
//     top: 50%;
//     transform: translate(-50%, -50%);
//     line-height: 14px !important;
//     width: unset !important;
//     color: #fff !important;
//     font-size: 14px !important;
//     font-family: "Work Sans", sans-serif !important;
//     font-weight: 400 !important;
//   }
// }
.cluster {
  --size: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--size) !important;
  height: var(--size) !important;

  &>img {
    width: 100%;
    clip: rect(0px, var(--size), var(--size), 0px) !important;
  }

  &>div {
    // padding-top: 2px;
    font-family: "Work Sans", sans-serif !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #fff !important;
  }
}

.gm-style-iw {

  // padding: 0 !important;
  // box-shadow: unset !important;
  // background-color: unset !important;
  // button {
  //   display: none !important;
  // }
}

// .avatar-marker {
//   border-radius: 50%;
//   border: 4px solid #fff;
//   // box-shadow: 0rem 0.1rem 0.1rem 0rem #121b211a;
//   position: relative;
//   &:after {
//     width: 0;
//     height: 0;
//     content: "";
//     border-left: 4px solid transparent;
//     border-right: 4px solid transparent;
//     border-top: 7px solid #fff;
//     display: inline-block;
//     position: absolute;
//     left: 50%;
//     bottom: -11px;
//     transform: translateX(-50%);
//   }
// }
.bg-birthday {
  // background-color: #e6f5e0;
  background-image: url(/assets/images/bg/bg-birthday.png);
}

.bg-anniversary {
  // background-color: #fcf8e3;

  background-image: url(/assets/images/bg/bg-anniversary.png);
}

.bg-pattern {
  background-image: url(/assets/images/bg/bg-pattern.png);
  // background-repeat: no-repeat;
  background-color: #eff4f6;
  background-blend-mode: color-dodge;
}
.bg-pattern1 {
    background: url(/assets/images/bg/bg-pattern2.png) 95% 50% no-repeat,
        url(/assets/images/bg/bg-wave.png) bottom left no-repeat;
        background-size: contain;
}

.main-height {
  height: calc(100vh - 3rem);
}

.card-chat {
  border-radius: 8px;
  background: #e3eef4;
  min-height: calc(100vh - 5rem);
}

.border-accent1 {
  border: 2px solid #31aab7 !important;
}
ng-autocomplete .autocomplete-overlay{
  pointer-events: none;
}
.nodata-sm{
  width: 3.875rem;
    aspect-ratio: 1;
    object-fit: contain;
}

// ===========================
// *******Location Map*******
// ===========================
.agm-info-window-content{
  font-family: "Work Sans", sans-serif;
}
.gm-style-mtc-bbw{
  margin: 1rem !important;
}
.gm-style-mtc {
  button,
  li {
    font-size: .875rem !important;
    font-weight: 500 !important;
    font-family: Work Sans !important;
    // color: #31AAB7 !important;
    color: #324552 !important;
  }
  button {
    height: 2.5rem !important;
  }
  ul {
    top: 2.5rem !important;
  }
}
button,.gm-style-iw-d{
  font-family: Work Sans !important;
}
.btn-map {
  font-size: .875rem !important;
  font-weight: 500 !important;
  color: #324552 !important;
  height: 2.5rem !important;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 2px -1px;
  // box-shadow: 0px 2px 4px 0px #69707533;
  border: 1px solid #EAEDEF;
  background-color: #fff;
  border-radius: 2px;
  display: flex;
  align-items: center;
}

.btn-breadcrumb:hover {
  background-color: #f4f4f4;
}
.btn-zoom {
  color: #31aab7 !important;
}
.gm-style-iw-d{
  overflow: auto!important;
}
.gm-style-iw-c{
  padding-right: .75rem !important;
  padding-bottom: .75rem !important;
}
.gm-style-iw-chr {
  position: relative;
  .gm-style-iw-ch{
    padding-top: .75rem !important;
  }
  .gm-ui-hover-effect {
    position: absolute !important;
    right: -10px;
    z-index: 1;
    width: 36px !important;
    height: 36px !important;
    outline: none !important;
    span {
      width: 16px !important;
      height: 16px !important;
      // margin: 12px;
    }
  }
}
// ==============================
// *******Location Map End*******
// ==============================
